import React from "react"
import { Link } from "gatsby"
import logo_notext from "../../imgs/logo-notext-nobg.png"

import style from "./header.module.css"

const Header = () => {
  return (
    <>
      <div className={style.attention}>
        Kedves Érdeklődők! Gyermekvállalás miatt a rendelésem várhatóan 2025 tavaszáig szünetel, ezért átmenetileg nem tudok új klienseket fogadni. Megértésüket köszönöm!
      </div>
      <header className={style.header}>
        <div className={style.nevjegy}>
          <Link to="/">
            <img src={logo_notext} alt="Logó"></img>
          </Link>
          <Link to="/">
            <h3 className={style.nev}>Jakab-Jánosi Eszter</h3>
          </Link>
        </div>

        <p>
          <Link to="/szolgaltatasok">Szolgáltatások</Link>
        </p>
        <p>
          <Link to="/arak">Árak</Link>
        </p>

        <p>
          <Link to="/kapcsolat">Kapcsolat</Link>
        </p>
      </header>
    </>
  )
}

export default Header
