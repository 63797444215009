import React from "react"

import Header from "../components/Header/Header.jsx"
import Welcome from "../components/welcomepage/Welcome/Welcome"
import Introduction from "../components/welcomepage/Introduction/Introduction.jsx"
import Footer from "../components/Footer/Footer.jsx"
import Education from "../components/welcomepage/Education/Education.jsx"
import { Helmet } from "react-helmet"

export default function Home() {
  return (
    <>
      <Helmet>
        <title>Bemutatkozás - Jakab-Jánosi Eszter, érdi pszichológus</title>

        <link
          rel="alternate"
          media="only screen and (max-width: 1000px)"
          href="https://jakabjanosieszter.hu/mobil"
        ></link>
        <meta
          name="description"
          content="Jakab-Jánosi Eszter okleveles pszichológus magánrendelése Érden. Gyermekpszichológiával, nevelési tanácsadással, családterápiával és párterápiával foglalkozom."
        />
      </Helmet>
      <Welcome>
        <Header />
      </Welcome>
      <Introduction />
      <Education />
      <Footer />
    </>
  )
}
