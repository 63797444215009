import React from "react"

import style from "./education.module.css"
import { useState } from "react"
import { useRef } from "react"
import { useEffect } from "react"

const Education = () => {
  const [opened, setOpened] = useState(false)
  const scrollTo = useRef(null)

  useEffect(() => {
    if (opened) {
      const { bottom } = scrollTo.current.getBoundingClientRect()
      if (
        !(
          bottom <=
          (window.innerHeight || document.documentElement.clientHeight)
        )
      )
        scrollTo.current.scrollIntoView({ behavior: "smooth", block: "start" })
    }
  }, [opened])

  return (
    <div
      className={`${style.container} ${opened ? style.heightened : ""}`}
      onClick={() => setOpened(!opened)}
      onKeyDown={() => setOpened(!opened)}
      role="button"
      tabIndex={0}
    >
      <p>Végzettségem</p>
      <p ref={scrollTo}>
        Okleveles pszichológusként az ELTE-n végeztem, tanácsadás és
        iskolapszichológia szakirányon. A gyermekekkel való foglalkozás régóta
        része az életemnek. Öt éven át az egyetem mellett több családnál is
        gyermekgondozóként dolgoztam, majd szakmai gyakorlatomat az ELTE
        Gyakorló Óvodában végeztem, ahol beszédfogyatékos és értelmileg
        akadályozott gyermekekkel is foglalkoztam. Diplomám megszerzése után a
        Bethesda Gyermekkórházban önkénteskedtem. Ezek a tapasztalatok egyre
        jobban megerősítettek abban, hogy a gyermekek pszichés megsegítése egy
        olyan terület, amelyet igazán elhivatottan tudnék vállalni. Jelenleg egy
        Pedagógiai Szakszolgálatnál dolgozom, ahol minden korosztályból érkeznek
        hozzám a gyerekek.  A későbbiek során érdeklődésem a klinikai szakirány
        felé is nyitottá vált, így a Pécsi Tudományegyetemen a klinikai
        gyermek-és ifjúságpszcihológus képzésen is tanulmányokba kezdtem. A
        Magyar Családterápiás Egyesületnél végeztem családterápiás
        alapképzésemet, jelenleg képzésben lévő családterapeutaként foglalkozom
        párokkal és családokkal a folyamatban lévő képzésem lezárásáig. A
        rendszerszemlélet megismerése által vált igazán láthatóvá számomra az,
        hogy egy gyermek vagy egy felnőtt problémája, tünete önmagában kevéssé,
        ám az ő családi kapcsolatainak minőségét, jellegét, történetét tekintve
        lesz csak igazán értelmezhető. Ezt a területet kitanulva komoly
        elköteleződéssel kezdtem család-és párterápiák vezetésébe.
      </p>
    </div>
  )
}

export default Education
