import React from "react"
import style from "./scrolldownbutton.module.css"
import { useState } from "react"
import { useEffect } from "react"

const ScrollDownButton = () => {
  useEffect(() => {
    const getWindowHeight = () => {
      const height = document.getElementById("welcomeImageCont").clientHeight
      return height
    }
    setWindowHeight(getWindowHeight())
    const handleResize = () => setWindowHeight(getWindowHeight())
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  const [windowHeight, setWindowHeight] = useState(0)

  const scrollDown = () => {
    window.scroll({ top: windowHeight, behavior: "smooth" })
  }

  return (
    <div className={style.container}>
      <p onClick={scrollDown} onKeyDown={scrollDown}>
        Bemutatkozás
      </p>
      <div
        className={style.scrollDown}
        onClick={scrollDown}
        onKeyDown={scrollDown}
      />
    </div>
  )
}

export default ScrollDownButton
