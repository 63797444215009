import React from "react"
import avatar from "../../../imgs/avatar.jpg"
import style from "./introduction.module.css"

const Introduction = () => {
  return (
    <div className={`${style.container} ${style.containerMobil}`}>
      <img src={avatar} alt="Profilkép"></img>
      <p>
        Jakab-Jánosi Eszter vagyok, okleveles pszichológus. Munkám során
        elsősorban gyermekekkel, családokkal és párokkal foglalkozom. Legyen szó
        gyermekekről vagy felnőttekről, igyekszem olyan biztonságos,
        szeretetteli, elfogadó légkört teremteni, amelyben valóban hatékony tud
        lenni a közös munka. Közös munkáról beszélek, hiszen a kliens számomra
        partner abban, hogy változást érjünk el. Az én munkám nem csak abból
        áll, hogy feltárom a kliens szeme láttára a problémás pontokat, de abból
        is, hogy eszközöket adok a kezébe ahhoz, hogy ezeket meg lehessen
        változtatni, ebben viszont már rá is kemény munka vár. Célomnak
        tekintem, hogy erre az energiabefektetésre megfelelően motiváljam a
        hozzám érkezőket, ezáltal hozzásegítve őket a kiegyensúlyozottabb,
        boldogabb mindennapokhoz.  Alapvetően rendszerszemléletben gondolkozom,
        így a hozzám érkezőket sosem önmagukban szemlélem, mint “problémás
        elem”, hanem elsősorban családi kapcsolatainak minőségével, családjának
        történetével összefüggésben.   Elhivatottan várom mindazokat a
        gyermekeket, felnőtteket, családokat és párokat, akik úgy érzik, olyan
        pszichés akadályba ütköztek, melynek terhét könnyebb volna egy szakértő
        segítségével, folyamatos támogatás mellett, együtt átdolgozni.
      </p>
    </div>
  )
}

export default Introduction
