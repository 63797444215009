import React from "react"

import style from "./welcome.module.css"
import ScrollDownButton from "../ScrollDownButton/ScrollDownButton"

const Welcome = ({ children }) => {
  return (
    <div className={style.container} id="welcomeImageCont">
      {children}
      <div className={`${style.felirat} ${style.feliratMobil}`}>
        <h1>Pszichológus Érden</h1>
        <h4>Gyermekpszichológia, párterápia, családterápia</h4>
      </div>
      <ScrollDownButton />
    </div>
  )
}

export default Welcome
